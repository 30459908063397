import Component from '../core/Component'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

const template = `
<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg"></div>
    <div class="pswp__scroll-wrap">
        <div class="pswp__container">
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
        </div>
        <div class="pswp__ui pswp__ui--hidden">
            <div class="pswp__top-bar">
                <div class="pswp__counter"></div>
                '<button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
                '<button class="pswp__button pswp__button--share" title="Share"></button>
                '<button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
                '<button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
                <div class="pswp__preloader">
                    <div class="pswp__preloader__icn">
                      <div class="pswp__preloader__cut">
                        <div class="pswp__preloader__donut"></div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div class="pswp__share-tooltip"></div>
            </div>
            '<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
            </button>
            '<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
            </button>
            <div class="pswp__caption">
                <div class="pswp__caption__center"></div>
            </div>
        </div>
    </div>
</div>
`

const buffer  = document.createElement('div')
buffer.innerHTML = template
document.body.appendChild(buffer)

const galleryRoot = buffer.querySelector('.pswp')

export default class Lightbox extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            links: []
        }
    }

    prepare() {
        this.items = this.ref.links
            .map(link => ({
                src: link.href,
                w: link.dataset.width,
                h: link.dataset.height
            }))
        this.ref.links.forEach(link => link.addEventListener('click', ::this.handleClick))
    }

    destroy() {
        if (this.gallery) {
            this.gallery.destroy()
        }
    }

    handleClick(event) {
        event.preventDefault()

        const index = this.ref.links.indexOf(event.currentTarget)

        const options = {
            index: Math.max(index, 0),
            shareEl: false,
            showHideOpacity: true,
            bgOpacity: .35,
            history: false,
            fullscreenEl: false,
            zoomEl:false,

            getThumbBoundsFn: index => {
                const thumbnail = this.ref.links[index].querySelector('img')
                const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
                const rect = thumbnail.getBoundingClientRect()

                return {
                    x: rect.left,
                    y: rect.top + pageYScroll,
                    w: rect.width
                }

            }
        }

        this.gallery = new PhotoSwipe(galleryRoot, PhotoSwipeUI_Default, this.items, options)
        this.gallery.init()
    }
}