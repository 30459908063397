// general
import Antispam from '../../components/Antispam'
// import CookieBubble from '../../components/CookieBubble'

// project
import SampleComponent from './SampleComponent'
import ScrollTo from './ScrollTo'
import Lightbox from './Lightbox'

const Components = {
    Antispam,
    // CookieBubble,
    Lightbox,
    SampleComponent,
    ScrollTo
}

export default Components