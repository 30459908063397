import Component from '../core/Component';
import scrollToElement from '../services/Viewport/scrollToElement';

export default class ScrollTo extends Component {

    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event) {
        const id = this.element.getAttribute('href').replace('#', '');


        const target = document.getElementById(id);

        if (target) {
            event.preventDefault();
            scrollToElement(target);
        }
    }

}